/*----------------------------------------*/
/*  01. Theme default
/*----------------------------------------*/

/* 1. Theme default css */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800;900&family=Roboto:wght@300;400;500;700;900&display=swap");
body {
  font-family: $roboto;
  font-weight: 400;
  font-style: normal;
  color: $body-text-color;
  font-size: 16px;
}
.tp-custom-container {
  max-width: 1630px;
  min-width: 1630px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  @media #{$xxxl} {
    max-width: 1570px;
    min-width: 1570px;
  }
  @media #{$xxl,$xl,$lg,$md,$xs} {
    max-width: 100%;
    min-width: 100%;
  }
}
.img,
img {
  max-width: 100%;
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.fix {
  overflow: hidden;
}
a,
button,
i {
  @include transition(0.3s);
  text-decoration: none;
}
a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}
a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}
a,
button {
  color: inherit;
  outline: medium none;
  text-decoration: none;
}
button:hover {
  color: inherit;
}
.nav-link:focus,
.nav-link:hover {
  color: inherit;
}
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
input,
select,
textarea {
  font-family: $roboto;
  font-weight: 400;
  opacity: 1;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $nunitosans;
  color: $heading-color-black;
  margin: 0px;
  font-style: normal;
  font-weight: 800;
  text-transform: normal;
  margin-bottom: 10px;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
ul {
  margin: 0px;
  padding: 0px;
}
p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: $body-text-color;
  margin-bottom: 10px;
}
i {
  line-height: 1;
  display: inline-block;
}
label {
  color: $body-text-color;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
.display-inline {
  display: inline-block;
}
// *::-moz-selection {
// 	background: $black;
// 	color: $white;
// 	text-shadow: none;
// }
// ::-moz-selection {
// 	background: $black;
// 	color:$white;
// 	text-shadow: none;
// }
// ::selection {
// 	background: $black;
// 	color: $white;
// 	text-shadow: none;
// }
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
input,
select {
  font-family: $roboto;
  font-weight: 400;
}
.custom-pad-20 {
  padding-left: 10px;
  padding-right: 10px;
}
.custom-mar-20 {
  margin-right: -10px;
  margin-left: -10px;
}
.radius-50 {
  border-radius: 50px;
}
/*--
    - Input Placeholder
-----------------------------------------*/
*input::-moz-placeholder {
  font-size: 16px;
  opacity: 1;
  font-family: $roboto;
  font-weight: 400;
}
*input::placeholder {
  font-size: 16px;
  opacity: 1;
  font-family: $roboto;
  font-weight: 400;
}

/*--
    - Common Classes
-----------------------------------------*/
.clear {
  clear: both;
}

.translate-y--10 {
  transform: translateY(-10px);
}
/*--
    - Background color
-----------------------------------------*/
.theme-bg {
  background: $theme-color;
}
.theme-yellow-bg {
  background: $theme-yellow;
}
.white-bg {
  background: $white;
}
.yellow-dark-bg {
  background: $theme-yellow-dark;
}
.blue-dark-bg {
  background: $theme-blue-dark;
}
.theme-dark-bg {
  background: $theme-dark-color;
}
.theme-dark-bg2 {
  background: #041459;
}
.theme-dark-bg3 {
  background: #18255e;
}
.grey-bg {
  background: $grey-bg;
}
.bg-green-light {
  background: #084d2b;
}
.bg-gray-light {
  background: $grey-bg-light;
}
/*--
    - color
-----------------------------------------*/
.white-color {
  color: $white;
}
.theme-yelow-color {
  color: $theme-yellow;
}
.theme-blue-color {
  color: $theme-blue;
}
.theme-color {
  color: $theme-color;
}
.font-normal {
  font-weight: 400 !important;
}
.color-theme-blue {
  color: $theme-blue !important;
}
.heading-color-black {
  color: $heading-color-black !important;
}
.heading-color-black-with-hover {
  color: $heading-color-black !important;
  &:hover {
    color: $theme-color !important;
  }
}
/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
@for $i from 1 through 40 {
  .mt-#{5 * $i} {
    margin-top: 5px * $i;
  }
}

/*-- Margin Bottom --*/
@for $i from 1 through 40 {
  .mb-#{5 * $i} {
    margin-bottom: 5px * $i;
  }
}
/*-- Margin Left --*/
@for $i from 1 through 40 {
  .ml-#{5 * $i} {
    margin-left: 5px * $i;
  }
}

/*-- Margin Right --*/
@for $i from 1 through 40 {
  .mr-#{5 * $i} {
    margin-right: 5px * $i;
  }
}

/*-- Padding Top --*/
@for $i from 1 through 40 {
  .pt-#{5 * $i} {
    padding-top: 5px * $i;
  }
}

/*-- Padding Bottom --*/
@for $i from 1 through 40 {
  .pb-#{5 * $i} {
    padding-bottom: 5px * $i;
  }
}

/*-- Padding Left --*/
@for $i from 1 through 40 {
  .pl-#{5 * $i} {
    padding-left: 5px * $i;
  }
}

/*-- Padding Right --*/
@for $i from 1 through 40 {
  .pr-#{5 * $i} {
    padding-right: 5px * $i;
  }
}

// others common css here :)

//section title style
.slider button.btn.btn-lg.btn-outline-light {
  background: #eabe0e;
  border-color: #eabe0e;
}
header {
  background: #222;
  padding: 15px 0;
}
.col-lg-4.col-md-4.col-sm-8.logo a.navbar-brand {
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 4px;
}
.col-lg-8.col-md-8.col-sm-4.menu a {
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
}
.home-header header {
  background: none;
}
.slider h2 {
  font-size: 70px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 150px 0 66px 0;
  color: #fff;
  text-shadow: 3px 2px 3px #000;
  position: relative;
  margin: 0 0 60px 0;
}
.slider h2:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 150px;
  background: #fff;
  bottom: 0;
  left: 44%;
  opacity: 0.5;
}
.home-header {
  background: url(../img/banner-1.png);
  background-size: cover;
  height: 80vh;
  position: relative;
}
.arrow-bottom-slider img {
  width: 36px;
}
.arrow-bottom-slider {
  width: 100%;
  float: left;
  text-align: center;
  position: absolute;
  bottom: 26px;
}
.letestpost {
  text-align: center;
  padding: var(--btn-p-topbottom) 0;
}
.letestpost h2 {
  color: #000;
  font-size: 44px;
  position: relative;
}
.letestpost .letestpost-p p {
  color: var(--text);
  font-size: 14px;
  display: inline-block;
  font-weight: 500;
  margin: 0 0;
}
.letestpost-p {
  width: 100%;
  float: left;
  text-align: center;
  padding: 30px 0;
}
.letestpost-img img {
  width: 50px;
}
.letestpost .letestpost-follow {
  font-size: 35px;
  font-weight: 700;
  color: #000;
  margin: 10px 0;
}

footer p {
  color: #fff;
  margin: 0;
  font-size: 16px;
}
footer {
  background: #000;
  padding: 20px 0;
}
ul.dropdown-menu.show li a {
  color: #000 !important;
}
.about-content textarea {
  height: 154px;
}

ul.nav li.dropdown:hover ul.dropdown-menu {
  display: block;
}

ul.dropdown-menu li a {
  color: #000 !important;
  font-weight: 600;
}

.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #eabe0e;
}
ul.dropdown-menu li a {
  padding: 10px 10px;
}
ul.dropdown-menu {
  padding: 0;
}

// theme-btn
.theme-btn {
  font-size: 16px;
  display: inline-block;
  font-weight: 500;
  border: none;
  outline: none;
  box-shadow: none;
  height: 60px;
  line-height: 60px;
  color: $white;
  background: $theme-color;
  text-align: center;
  padding: 0px 38px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &:hover {
    background: $theme-yellow;
    color: $body-text-color;
  }
}
